import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.css";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.tomSelect = new TomSelect(this.inputTarget, {
      plugins: ["remove_button"],
      placeholder: "Search...",
      allowEmptyOption: true,
      searchField: ["text"],
      maxOptions: 1000,
      render: {
        no_results: function (data, escape) {
          return `<div class="px-4 py-2 text-base-content/70">No results found for "${escape(data.input)}"</div>`;
        },
      },
    });
  }

  disconnect() {
    this.tomSelect.destroy();
  }
}
