import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";

export default class extends Controller {
  connect() {
    this.initializeSwiper();
  }

  initializeSwiper() {
    new Swiper(this.element.querySelector(".swiper"), {
      modules: [Navigation],
      loop: true,
      slidesPerView: 2,
      spaceBetween: 8,
      speed: 300,
      navigation: {
        nextEl: ".swiper-custom-next",
        prevEl: ".swiper-custom-prev",
      },
      breakpoints: {
        320: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 5,
        },
      },
    });
  }

  disconnect() {
    const swiperInstance = this.element.querySelector(".swiper")?.swiper;
    if (swiperInstance) {
      swiperInstance.destroy();
    }
  }
}
