import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input", "progress", "progressBar", "progressText"];

  handleFileSelect(event) {
    const progressElement = event.target.closest("[data-direct-upload-target='input']").nextElementSibling;

    progressElement.classList.remove("hidden");

    Array.from(event.target.files).forEach((file) => {
      this.uploadFile(file, event.target, progressElement);
    });
  }

  uploadFile(file, input, progressElement) {
    const url = input.dataset.directUploadUrl;
    const upload = new DirectUpload(file, url, {
      directUploadWillStoreFileWithXHR: (xhr) => {
        this.trackProgress(xhr, progressElement);
      },
    });

    upload.create((error, blob) => {
      if (error) {
        console.error("Error uploading file:", error);
      } else {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = input.name;
        hiddenField.value = blob.signed_id;
        input.closest("form").appendChild(hiddenField);
      }
    });
  }

  trackProgress(xhr, progressElement) {
    const progressBar = progressElement.querySelector("[data-direct-upload-target='progressBar']");
    const progressText = progressElement.querySelector("[data-direct-upload-target='progressText']");

    // Only add the event listener if we found both elements
    if (progressBar && progressText) {
      xhr.upload.addEventListener("progress", (event) => {
        const progress = Math.floor((event.loaded / event.total) * 100);
        progressBar.value = progress;
        progressText.textContent = `${progress}%`;
        console.log(`Upload progress: ${progress}%`);
      });
    } else {
      // Fallback to just console logging if UI elements aren't found
      xhr.upload.addEventListener("progress", (event) => {
        const progress = Math.floor((event.loaded / event.total) * 100);
        console.log(`Upload progress: ${progress}%`);
      });
    }
  }
}
