import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static values = {
    publicKey: String,
    clientSecret: String,
    returnUrl: String,
    orderId: String,
    checkoutSessionId: String,
  };

  connect() {
    if (window?.Stripe) {
      this.initStripe();
    } else {
      document.addEventListener("stripe-loaded", () => this.initStripe());
    }

    this.giftNote = "";
  }

  async initStripe() {
    try {
      this.stripe = Stripe(this.publicKeyValue, {
        betas: ["custom_checkout_beta_5"],
      });

      // Initialize embedded Stripe elements based on current theme. This can only be set on initialization.
      this.currentTheme = localStorage.getItem("theme") || "dreamer";
      const appearance = {
        theme: ["dark", "dracula", "forest", "black"].includes(this.currentTheme) ? "night" : "flat",
      };

      const checkout = await this.stripe.initCheckout({
        clientSecret: this.clientSecretValue,
        elementsOptions: { appearance },
      });

      const emailInput = document.getElementById("email");
      const emailErrors = document.getElementById("email-errors");

      emailInput.addEventListener("change", () => {
        // Clear any validation errors
        emailErrors.textContent = "";
      });

      emailInput.addEventListener("blur", () => {
        const newEmail = emailInput.value;
        checkout.updateEmail(newEmail).then((result) => {
          if (result.error) {
            emailErrors.textContent = result.error.message;
          }
        });
      });

      const paymentElement = checkout.createElement("payment", { layout: "accordion" });
      paymentElement.mount("#payment-element");

      if (!document.getElementById("payment-element").children.length) {
        console.error("Payment element not mounted properly");
        return;
      }

      const button = document.getElementById("pay-button");
      const errors = document.getElementById("confirm-errors");

      button.addEventListener("click", async () => {
        // Clear any validation errors
        errors.textContent = "";

        try {
          this.setLoading(true);
          const result = await checkout.confirm({
            redirect: "if_required",
          });

          if (result.type === "error") {
            errors.textContent = result.error.message;
            return;
          }

          // Update the order with the checkout session id and gift note
          const response = await patch(`/orders/${this.orderIdValue}`, {
            body: JSON.stringify({
              order: {
                order_id: this.orderIdValue,
                gift_note: this.giftNote,
                purchaser_email: emailInput.value,
                checkout_session_id: this.checkoutSessionIdValue,
              },
            }),
            contentType: "application/json",
          });

          // After the order is updated, redirect to the return URL
          if (response.ok) {
            window.location.href = this.returnUrlValue;
          }
        } catch (error) {
          this.setLoading(false);
          console.error("Order creation failed:", error);
          errors.textContent = "Failed to create order";
        } finally {
          this.setLoading(false);
        }
      });
    } catch (error) {
      this.setLoading(false);
      console.error("Stripe setup error:", error);
    }
  }

  setLoading(isLoading) {
    if (isLoading) {
      document.querySelector("#pay-button").disabled = true;
      document.querySelector("#pay-button").innerHTML = "Processing...";
    } else {
      document.querySelector("#pay-button").disabled = false;
      document.querySelector("#pay-button").innerHTML = "Send Gift";
    }
  }

  updateGiftNote(event) {
    this.giftNote = event.target.value;
  }
}
