import { createConsumer } from "@rails/actioncable";

// Determine WebSocket URL based on current window location
const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
let host = window.location.host.replace(/^www\./, "");
host = host.replace("app.", "");

const consumer = `${wsProtocol}//api.${host}/cable`;

// Rails will automatically connect to the cable endpoint on the API subdomain
export default createConsumer(consumer);
