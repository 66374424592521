import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    // Get initial theme from localStorage or default to "dreamer"
    const savedTheme = localStorage.getItem("theme") || "dreamer";
    this.setTheme(savedTheme);

    // Set the select element's value to match the current theme
    if (this.hasSelectTarget) {
      this.selectTarget.value = savedTheme;
    }
  }

  // Called when the select value changes
  change(event) {
    const newTheme = event.target.value;
    this.setTheme(newTheme);
  }

  setTheme(theme) {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }
}
