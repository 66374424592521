import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const element = this.element;

    element.addEventListener("input", function (e) {
      if (e.inputType === "deleteContentBackward") {
        if (e.target.value.length < 6) {
          element.setSelectionRange(e.target.value.length - 1, e.target.value.length - 1);
        }
      }
    });
  }

  format() {
    // Remove all non-numeric characters
    let phoneNumber = this.element.value.replace(/\D/g, "");

    // Ensure only 10 digits are allowed
    phoneNumber = phoneNumber.slice(0, 10);

    // Format the phone number as (XXX) XXX-XXXX
    let formattedNumber = "()";
    if (phoneNumber.length >= 1) {
      // Introduce open and closed parenthesis when user inputs first digit
      formattedNumber = `${formattedNumber.slice(0, 1)}${phoneNumber.substring(0, 3)}${formattedNumber.slice(1, 2)}`;
    }
    if (phoneNumber.length > 3) {
      formattedNumber += ` ${phoneNumber.substring(3, 6)}`;
    }
    if (phoneNumber.length > 6) {
      // Add hyphen after user enters 6th digit
      formattedNumber += "-" + phoneNumber.substring(6);
    }

    if (phoneNumber.length === 0) {
      // Clear the input if no digits are entered
      formattedNumber = "";
    }

    this.element.value = formattedNumber;
  }
}
