import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.css";

const VARIANT_OPTIONS = [
  { text: "size", value: "size" },
  { text: "color", value: "color" },
  { text: "material", value: "material" },
  { text: "pattern", value: "pattern" },
  { text: "waist", value: "waist" },
  { text: "band", value: "band" },
  { text: "cup", value: "cup" },
  { text: "weight", value: "weight" },
  { text: "dimensions", value: "dimensions" },
  { text: "fit", value: "fit" },
  { text: "style", value: "style" },
  { text: "neckline", value: "neckline" },
  { text: "sleeve length", value: "sleeve length" },
  { text: "inseam", value: "inseam" },
  { text: "model", value: "model" },
  { text: "capacity", value: "capacity" },
  { text: "type", value: "type" },
];

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const initialValue = this.inputTarget.name.match(/\[options\]\[(.*?)\]/)?.[1];

    this.tomSelect = new TomSelect(this.inputTarget, {
      plugins: ["remove_button"],
      placeholder: "Option name (e.g. size)",
      allowEmptyOption: true,
      searchField: ["text"],
      maxOptions: 1000,
      options: VARIANT_OPTIONS,
      items: initialValue ? [initialValue] : [],
      render: {
        no_results: function (data, escape) {
          return `<div class="px-4 py-2 text-base-content/70">No results found for "${escape(data.input)}"</div>`;
        },
      },
    });
  }

  disconnect() {
    this.tomSelect.destroy();
  }
}
