import { Controller } from "@hotwired/stimulus";
import * as amplitude from "@amplitude/analytics-browser";

// Connects to data-controller="event-tracker"
export default class extends Controller {
  static values = {
    properties: Object,
  };

  track() {
    const properties = { ...this.propertiesValue };
    amplitude.track(properties);
  }
}
