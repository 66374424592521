import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = ["input", "preview", "text", "progress", "progressBar", "progressText"];

  connect() {}

  click() {
    this.inputTarget.click();
  }

  fileSelected(e) {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      this.handleFile(file);
    }
  }

  dragover(e) {
    e.preventDefault();
  }

  dragenter(e) {
    e.preventDefault();
    e.currentTarget.classList.add("border-primary");
  }

  dragleave(e) {
    e.preventDefault();
    e.currentTarget.classList.remove("border-primary");
  }

  drop(e) {
    e.preventDefault();
    e.currentTarget.classList.remove("border-primary");

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      this.handleFile(file);
    }
  }

  paste(e) {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        this.handleFile(file);
        break;
      }
    }
  }

  handleFile(file) {
    // Show preview immediately
    const reader = new FileReader();
    reader.onload = (e) => {
      this.previewTarget.innerHTML = `<img src="${e.target.result}" class="max-h-48 mx-auto">`;
      this.textTarget.classList.add("hidden");
    };
    reader.readAsDataURL(file);

    // Show progress bar
    this.progressTarget.classList.remove("hidden");

    // Handle direct upload
    const upload = new DirectUpload(file, this.inputTarget.dataset.directUploadUrl, {
      directUploadWillStoreFileWithXHR: (xhr) => {
        xhr.upload.addEventListener("progress", (event) => {
          const progress = Math.floor((event.loaded / event.total) * 100);
          this.progressBarTarget.value = progress;
          this.progressTextTarget.textContent = `${progress}%`;
        });
      },
    });

    upload.create((error, blob) => {
      if (error) {
        console.error("Error uploading file:", error);
      } else {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = this.inputTarget.name;
        hiddenField.value = blob.signed_id;
        this.inputTarget.closest("form").appendChild(hiddenField);
      }
    });
  }
}
