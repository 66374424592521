import consumer from "./consumer";

consumer.subscriptions.create(
  { channel: "Noticed::NotificationChannel" },
  {
    received(data) {
      console.log("Received data from notifications channel", data);
      document.getElementById("notifications-wrapper").classList.remove("hidden");
      document.getElementById("notifications-count").textContent = data.unread_notifications_count;
    },
  }
);
