import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["optionsContainer", "optionRow", "optionValue"];

  connect() {
    this.element.closest("form")?.addEventListener("submit", this.handleSubmit.bind(this));
  }

  addOption(event) {
    event.preventDefault();
    const template = `
      <div class="flex flex-col gap-2 mt-4" data-variant-options-new-target="optionRow">
        <select
          name="products_native[variant_options][][type]"
          class="w-full"
          data-controller="variant-option-select"
          data-variant-option-select-target="input">
        </select>
        <textarea
          name="products_native[variant_options][][values]"
          class="textarea textarea-bordered w-full"
          placeholder="Enter values, one per line"
          rows="4"
          data-variant-options-new-target="optionValues"></textarea>
        <button type="button" class="btn btn-ghost" data-action="variant-options-new#removeOption">
          Remove Option
        </button>
      </div>
    `;
    this.optionsContainerTarget.insertAdjacentHTML("beforeend", template);
  }

  removeOption(event) {
    event.preventDefault();
    event.currentTarget.closest("[data-variant-options-new-target='optionRow']").remove();
  }

  handleSubmit(event) {
    const rows = this.optionRowTargets;

    rows.forEach((row) => {
      const select = row.querySelector("[data-variant-option-select-target='input']");
      const textarea = row.querySelector("[data-variant-options-new-target='optionValues']");

      if (select?.value && textarea?.value) {
        // Create hidden inputs to ensure empty textareas are properly submitted
        const typeInput = document.createElement("input");
        typeInput.type = "hidden";
        typeInput.name = "products_native[variant_options][][type]";
        typeInput.value = select.value;
        this.element.appendChild(typeInput);

        const valuesInput = document.createElement("input");
        valuesInput.type = "hidden";
        valuesInput.name = "products_native[variant_options][][values]";
        valuesInput.value = textarea.value;
        this.element.appendChild(valuesInput);
      }
    });

    // Remove the original input fields
    this.optionRowTargets.forEach((row) => {
      row.remove();
    });
  }
}
