import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "template", "nestedField"];
  static values = { identifier: String };

  add(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());

    // Find the variants container and the button row
    const variantsContainer = this.containerTarget;
    variantsContainer.insertAdjacentHTML("beforeend", content);
  }

  remove(event) {
    event.preventDefault();
    const wrapper = this.nestedFieldTargets.find((target) => target.contains(event.target));

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";
      const index = wrapper.dataset.variantIndex;
      wrapper.innerHTML += `<input type="hidden" name="products_native[${this.identifierValue}][${index}][_destroy]" value="1">`;
    }
  }
}
