import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

export default class extends Controller {
  static targets = ["form", "input", "mobileSearch"];

  initialize() {
    this.search = debounce(this.search.bind(this), 300);
  }

  search() {
    if (this.inputTarget.value.length > 0) {
      this.formTarget.requestSubmit();
    } else {
      const results = document.getElementById("search-results");
      const mobileResults = document.getElementById("mobile-search-results");
      if (results) results.innerHTML = "";
      if (mobileResults) mobileResults.innerHTML = "";
    }
  }

  openMobileSearch() {
    this.mobileSearchTarget.classList.remove("hidden");
    this.inputTarget.focus();
    document.body.style.overflow = "hidden";
  }

  closeMobileSearch() {
    this.mobileSearchTarget.classList.add("hidden");
    document.body.style.overflow = "";
    this.inputTarget.value = "";
    const mobileResults = document.getElementById("mobile-search-results");
    if (mobileResults) mobileResults.innerHTML = "";
  }
}
