import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileInput", "previewImage"];

  preview() {
    const images = this.fileInputTarget.files;
    [...images].forEach((image, i) => {
      const imageSource = URL.createObjectURL(image);
      this.previewImageTargets[i].src = imageSource;
      this.previewImageTargets[i].classList.replace("hidden", "block");
    });
  }
}
