import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["optionsContainer", "optionRow", "optionValue"];

  connect() {
    this.element.closest("form")?.addEventListener("submit", this.handleSubmit.bind(this));
  }

  addOption(event) {
    event.preventDefault();
    const index = this.element.dataset.variantIndex;
    const template = `
      <div class="flex gap-2 mt-2" data-variant-options-edit-target="optionRow">
        <select
          name="products_native[variants_attributes][${index}][options][key]"
          class="w-full"
          data-controller="variant-option-select"
          data-variant-option-select-target="input">
        </select>
        <input type="text"
          name="products_native[variants_attributes][${index}][options][value]"
          class="input input-bordered w-full"
          placeholder="Option value (e.g. 16 oz)"
          data-variant-options-edit-target="optionValue">
        <button type="button" class="btn btn-ghost" data-action="variant-options-edit#removeOption">
          <i class="ph ph-x text-lg"></i>
        </button>
      </div>
    `;
    this.optionsContainerTarget.insertAdjacentHTML("beforeend", template);
  }

  removeOption(event) {
    event.preventDefault();
    event.currentTarget.closest("[data-variant-options-edit-target='optionRow']").remove();
  }

  handleSubmit(event) {
    const rows = this.optionRowTargets;
    const index = this.element.dataset.variantIndex;

    // If there are no option rows or they've all been removed,
    // add a hidden input to explicitly set options to an empty string
    if (rows.length === 0) {
      const emptyOptionsInput = document.createElement("input");
      emptyOptionsInput.type = "hidden";
      emptyOptionsInput.name = `products_native[variants_attributes][${index}][options]`;
      emptyOptionsInput.value = "";
      this.element.appendChild(emptyOptionsInput);
      return;
    }

    rows.forEach((row) => {
      const tomSelectWrapper = row.querySelector(".ts-wrapper");
      const selectedItem = tomSelectWrapper?.querySelector(".item");
      const optionValue = row.querySelector("[data-variant-options-edit-target='optionValue']")?.value;

      // Only create hidden input if we have both a selected option type and a value
      if (selectedItem?.dataset.value && optionValue) {
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = `products_native[variants_attributes][${index}][options][${selectedItem.dataset.value}]`;
        hiddenInput.value = optionValue;
        this.element.appendChild(hiddenInput);
      }
    });

    // Remove the original input fields
    this.optionRowTargets.forEach((row) => {
      row.remove();
    });
  }
}
