import { Controller } from "@hotwired/stimulus";
import { loadStripe } from "@stripe/stripe-js";

export default class extends Controller {
  static targets = ["container", "error"];
  static values = {
    clientSecret: String,
    orderNumber: String,
    stripePublicKey: String,
    stripeAccountId: String,
  };

  async connect() {
    await this.initializeStripe();
  }

  async initializeStripe() {
    try {
      const stripe = await loadStripe(this.stripePublicKeyValue, { stripeAccount: this.stripeAccountIdValue });

      if (!stripe) {
        throw new Error("Failed to load Stripe");
      }

      const checkoutObj = await stripe.initEmbeddedCheckout({
        clientSecret: this.clientSecretValue,
        onComplete: () => {
          console.log("Checkout completed");
          window.location.href = "/checkout/thank-you?order_number=" + this.orderNumberValue;
        },
      });

      checkoutObj.mount(this.containerTarget);
    } catch (error) {
      console.error("Stripe initialization error:", error);
      if (this.hasErrorTarget) {
        this.errorTarget.textContent = error.message;
      }
    }
  }

  disconnect() {}
}
