import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open(event) {
    const modalId = event.currentTarget.dataset.modalTarget;
    const modal = document.getElementById(modalId);
    modal.showModal();
  }

  close() {
    const modal = this.element.closest("dialog");
    modal.close();
  }
}
