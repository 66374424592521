// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import * as amplitude from "@amplitude/analytics-browser";
import LocalTime from "local-time";
import "./controllers";
import "./channels";

LocalTime.start();
if (process.env.NODE_ENV === "production") {
  amplitude.init("fc20b34217427bd44715d627d58edc49", {
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
      elementInteractions: true,
    },
  });
}
