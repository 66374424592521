import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["category", "subCategory", "subCategoryContainer"];

  connect() {
    // Get the initial category from the URL params
    const params = new URLSearchParams(window.location.search);
    const category = params.get("category");

    if (category) {
      this.selectCategory({ target: { value: category } });
    }
  }

  selectCategory(event) {
    // Iterate through all sub categories first and then the top level categories
    // If we find a match in the sub categories, we need to also select the top level category

    // Assume the selected parent category is the one that was clicked. In the event that it is actually a sub
    // category, we will reassign it to the parent category
    let selectedParentCategory = event.target.value;
    let selectedSubCategory = event.target.value;
    let subCategoryCount = 0;

    // Iterate through all subcategories a first time to see if the selected category is actually a sub category
    // If we find a match, we need to reassign the value of selectedParentCategory to selectedSubCategory and then
    // figure out the actual parent category
    this.subCategoryTargets.forEach((subCategory) => {
      if (subCategory.dataset.categoryValue === event.target.value) {
        selectedSubCategory = selectedParentCategory;
        selectedParentCategory = subCategory.dataset.parentCategory;
      }
    });

    // Iterate through all subcategories againt to display them based on the selected parent category
    // Also, if we have a selectedSubCategory, we need to set the button style
    this.subCategoryTargets.forEach((subCategory) => {
      if (selectedParentCategory === subCategory.dataset.parentCategory) {
        subCategory.classList.remove("hidden");
        subCategoryCount += 1;
      } else {
        subCategory.classList.add("hidden");
      }

      if (selectedSubCategory === subCategory.dataset.categoryValue) {
        subCategory.querySelector("span").classList.add("btn-primary");
      } else {
        subCategory.querySelector("span").classList.remove("btn-primary");
      }
    });

    if (subCategoryCount > 0) {
      // Show the sub category container if subcategories are present
      this.subCategoryContainerTarget.classList.remove("hidden");
    } else {
      this.subCategoryContainerTarget.classList.add("hidden");
    }

    // Iterate through all top level categories
    this.categoryTargets.forEach((category) => {
      // console.log(">>>", category, category.dataset.categoryValue, event.target.value, selectedParentCategory);

      const subCategoryMatchesParent = selectedParentCategory === category.dataset.categoryValue;
      const parentCategoryMatchesSelection = category.dataset.categoryValue === event.target.value;

      if (subCategoryMatchesParent || parentCategoryMatchesSelection) {
        category.querySelector("span").classList.add("btn-primary");
      } else {
        category.querySelector("span").classList.remove("btn-primary");
      }
    });
  }

  clearAllCategories() {
    console.log("Clearing all categories");

    this.categoryTargets.forEach((category) => {
      category.querySelector("span").classList.remove("btn-primary");
      category.querySelector("input").checked = false;
    });

    this.subCategoryTargets.forEach((subCategory) => {
      subCategory.classList.add("hidden");
      subCategory.querySelector("span").classList.remove("btn-primary");
      subCategory.querySelector("input").checked = false;
    });

    this.subCategoryContainerTarget.classList.add("hidden");
  }
}
