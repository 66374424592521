import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

export default class extends Controller {
  static values = {
    debounce: { type: Number, default: 300 },
  };

  initialize() {
    this.submit = debounce(this.submit.bind(this), this.debounceValue);
  }

  submit() {
    this.element.requestSubmit();
  }
}
