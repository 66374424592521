import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  async invoke() {
    try {
      await window.navigator.share({ url: window.location.href });
    } catch (error) {
      console.error(error);
    }
  }
}
