import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["variantId", "optionSelect", "cashContent", "purchaseContent", "variantPrice"];
  static values = { variants: Array };

  connect() {
    if (this.variantsValue.length === 1) {
      const variant = this.variantsValue[0];
      this.variantIdTarget.value = variant.id;
      this.variantPriceTarget.textContent = `$${variant.price}`;
    }
    this.toggleContent();
  }

  updateVariantId() {
    const options = {};
    this.optionSelectTargets.forEach((select) => {
      const optionType = select.name.match(/\[(.*?)\]/)[1];
      options[optionType] = select.value;
    });

    const variant = this.variantsValue.find((v) =>
      Object.entries(v.options).every(([key, value]) => options[key.toLowerCase()] === value)
    );

    if (variant) {
      this.variantIdTarget.value = variant.id;
      this.variantPriceTarget.textContent = `$${variant.price}`;
    }
  }

  toggleContent() {
    const cashSelected = this.element.querySelector("input[value='cash']").checked;
    this.cashContentTarget.classList.toggle("hidden", !cashSelected);
    this.purchaseContentTarget.classList.toggle("hidden", cashSelected);
  }
}
